import React from "react";
import './WebPage.css';


function Registration() {
  return (
    <div className="registration">
      <div class="container pt-3 pb-5 mb-3 mt-3" style={{height: "80vh"}}>
        <iframe src='https://docs.google.com/forms/d/e/1FAIpQLSfIDFrSKjvbPmLqEb78XFzhEo5Q9IX1lnLdWa-lHi_0-Yq2rQ/viewform?embedded=true' width="100%" height="100%" frameborder='0' marginheight='0' marginwidth='0' overflow="hidden">Loading...</iframe>
      </div>
    </div>
  );
}

export default Registration;