import React from "react";
import './WebPage.css';

function Gallery() {
  return (
    <div className="gallery">
      <div class="container pt-3 pb-5 mb-5 mt-3">
        <div style={{ textAlign: "left" }} class="col-lg-6">
          <h2>Gallery</h2>
          <p>
          
          </p>
        </div>
      </div>
    </div>
  );
}

export default Gallery;