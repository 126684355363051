import React from "react";
import './WebPage.css';
import { Container, Row, Col } from "react-bootstrap";
import "./Awards.css";
import awards2021img1 from '../Dream Art/Awards/2021_Scholastic/2021_big_pic.jpg';
import img2020Scholastic1 from '../Dream Art/Awards/2020 Scholastic/1.jpg';
import img2020Scholastic2 from '../Dream Art/Awards/2020 Scholastic/2.jpg';
import img2020Scholastic3 from '../Dream Art/Awards/2020 Scholastic/6.jpg';
import img2020Scholastic4 from '../Dream Art/Awards/2020 Scholastic/4.jpg';
import img2020Scholastic5 from '../Dream Art/Awards/2020 Scholastic/5.jpg';
import img2020Scholastic6 from '../Dream Art/Awards/2020 Scholastic/3.jpg';
import img2020Scholastic7 from '../Dream Art/Awards/2020 Scholastic/7.jpg';
import img2020Youth1 from '../Dream Art/Awards/2020  Youth Art/2020_youth_art_big_pic.jpg';
import img2020AP from '../Dream Art/Awards/2020   AP/AP Art.jpg';
import img2020Summer1 from '../Dream Art/Awards/2020 SUMMER SCHOOL/1.jpg';
import img2020Summer2 from '../Dream Art/Awards/2020 SUMMER SCHOOL/2.jpg';
import img2020Summer3 from '../Dream Art/Awards/2020 SUMMER SCHOOL/3.jpg';
import img2019Toyota1 from '../Dream Art/Awards/2019  Toyota/1.jpg';
import img2019Toyota2 from '../Dream Art/Awards/2019  Toyota/2.jpg';
import img2019FineArts from '../Dream Art/Awards/2019  Fine Arts League of Cupertino/1.jpg';
import img2019Summer1 from '../Dream Art/Awards/2019 SUMMER SCHOOL/1.jpg';
import img2019Summer2 from '../Dream Art/Awards/2019 SUMMER SCHOOL/2.jpg';
import img2019Summer3 from '../Dream Art/Awards/2019 SUMMER SCHOOL/3.jpg';
import img2017ArtCenter1 from '../Dream Art/Awards/2017 ARTCENTER/1.jpg';
import img2017ArtCenter2 from '../Dream Art/Awards/2017 ARTCENTER/2.jpg';
import img2016FutureStars1 from '../Dream Art/Awards/2016 Future Stars/Nina Pan.jpg';
import img2016FutureStars2 from '../Dream Art/Awards/2016 Future Stars/Zhiyuan Li.jpg';
import img2016California1 from '../Dream Art/Awards/2016  California School Employees Association/Amy.jpg';
import img2016California2 from '../Dream Art/Awards/2016  California School Employees Association/Nina.jpg';
import img2016California3 from '../Dream Art/Awards/2016  California School Employees Association/3.jpg';
import asianAmerican1 from '../Dream Art/library/1.jpg';
import asianAmerican2 from '../Dream Art/library/2.jpg';
import asianAmerican3 from '../Dream Art/library/3.jpg';
import asianAmerican4 from '../Dream Art/library/4.jpg';
import asianAmerican5 from '../Dream Art/library/5.jpg';
import asianAmerican6 from '../Dream Art/library/6.jpg';
import asianAmerican7 from '../Dream Art/library/7.jpg';
import asianAmerican8 from '../Dream Art/library/8.jpg';
import apArt2021_1 from '../Dream Art/2021AP Art/1.jpg';
import apArt2021_2 from '../Dream Art/2021AP Art/2.jpg';
import Youth_Art_Contest_2021 from '../Dream Art/Awards/2021_Youth_Art_Contest.jpg';
import Beijing_Olympic_2022 from '../Dream Art/Awards/Beijing_Olympic_2022.jpg';
import img2022scholar1 from '../Dream Art/Awards/2022 scholar 1.jpg';
import img2022scholar2 from '../Dream Art/Awards/2022 scholar 2.jpg';
import img2022scholar3 from '../Dream Art/Awards/2022 scholar 3.jpg';
import img2022xuan from '../Dream Art/Awards/2022 xuan.jpg';
import img2022AP from '../Dream Art/Awards/2022-2023/2022AP.jpg';
import img2023AP from '../Dream Art/Awards/2022-2023/2023AP.jpg';
import img2022youthart from '../Dream Art/Awards/2022-2023/2022Youth_Art.jpg';
import img2023freedom from '../Dream Art/Awards/2022-2023/2023Freedom.jpg';
import img2023scholar1 from '../Dream Art/Awards/2022-2023/2023Gold_Keys.jpg';
import img2023scholar2 from '../Dream Art/Awards/2022-2023/2023Gold_Certifications.jpg';

function Awards() {
  return (
    <div className="awards">
      <div class="container pt-3 pb-3 mb-3 mt-3">
        <div style={{ textAlign: "left" }} class="col-lg-8">
          <h2>Awards</h2>
          <p>
            2023: <br />
            <ul>
              <li>Scholastic Art and Writing Awards:
                <ul>
                  <li>9 Gold Keys</li>
                  <li>32 Silver Keys</li>
                  <li>34 Honorable Mentions</li>
                </ul>
                <br />
                <div class={window.innerWidth < 1000 ? "" : "container"}>
                  {window.innerWidth < 1000 ? null : <img className="resize-collection" src={img2023scholar1} fluid />}
                  <br />
                  {window.innerWidth < 1000 ? null : <img className="resize-collection" src={img2023scholar2} fluid />}
                </div>
                <br />
              </li>
              <li>
                Americans Freedom Festival Art Contets: 8 Awards
                <br />
                {window.innerWidth < 1000 ? null : <img className="resize-collection" src={img2023freedom} fluid />}
                <br /> <br />
              </li>
            </ul>
            <ul>
              <li>
                AP Art: All students under my mentorship earned 5 - full score.
                <br /> <br />
                {window.innerWidth < 1000 ? null : <img className="resize-collection" src={img2023AP} fluid />}
                <br /> <br />
              </li>
            </ul>
          </p>
          <p>
            2022: <br />
            <ul>
            <li> 
                Youth Art Contest:
                <ul>
                  <li>30 Gold Awards </li>
                  <li>24 Silver Awards</li>
                  <li>4 Bronze Awards</li>
                </ul>
                 <br /> 
                {window.innerWidth < 1000 ? null : <img className="resize-collection" src={img2022youthart} fluid />}
                <br /> <br />
              </li>
              <li>Scholastic Art and Writing Awards:
                <ul>
                  <li>1 Gold Key</li>
                  <li>6 Silver Keys</li>
                  <li>11 Honorable Mentions</li>
                </ul>
                <br />
                <div class={window.innerWidth < 1000 ? "" : "container"}>
                  {window.innerWidth < 1000 ? null : <img className="resize-collection" src={img2022scholar1} fluid />}
                  <br />
                  {window.innerWidth < 1000 ? null : <img className="resize-collection" src={img2022scholar2} fluid />}
                  <br />
                  {window.innerWidth < 1000 ? null : <img className="resize-collection" src={img2022scholar3} fluid />}                  
                </div>
                <br />
              </li>
            </ul>
            <ul>
              <li>
                AP Art: All students under my mentorship earned 5 - full score.
                <br /> <br />
                {window.innerWidth < 1000 ? null : <img className="resize-collection" src={img2022AP} fluid />}
                <br /> <br />
              </li>
              <li>University and College Admission:
              <p style={{textAlign: "left"}}>
              One of my students has been admitted by Rhode Island School of Design, SAIC, Pratt and UIUC, all with scholarships offered.  SAIC offered $71400 scholarship and Pratt Institute offered President Award in amount of $88000.  The total amount of scholarship in the first year reaches about $31000. 
                 </p>
                <br />
                <div class={window.innerWidth < 1000 ? "" : "container"}>
                  {window.innerWidth < 1000 ? null : <img className="resize-collection" src={img2022xuan} fluid />}
                </div>
                <br />

              </li>
            </ul>
          </p>
          <p>
            2021: <br />
            <ul>
              <li> 
                Youth Art Contest:
                <ul>
                  <li>Grade K-2 &nbsp;&emsp; 1 Silver Award &nbsp; &emsp;&nbsp; 1 Bronze Award &nbsp; &emsp;&nbsp; 2 Finalist Awards </li>
                  <li>Grade 3-5 &nbsp;&emsp; 2 Gold Awards &ensp; &emsp;&nbsp; 1 Bronze Award</li>
                  <li>Grade 6-8 &nbsp;&emsp; 4 Gold Awards &emsp;&emsp; 5 Silver Awards &emsp; &emsp;&nbsp; 1 Bronze Award &nbsp; &emsp;&nbsp; 2 Honorable Mentions</li>
                  <li>Grade 9-10 &nbsp;&nbsp;&nbsp; 2 Gold Awards &nbsp; &emsp;&nbsp; 2 Silver Awards &ensp; &emsp;&emsp; 1 Finalist Award</li>
                  <li>Grade 11-12 &nbsp; 1 Silver Award &emsp;&nbsp;&nbsp; 1 Bronze Award &nbsp;&nbsp;&ensp;&emsp; 1 Finalist Award</li>
                 </ul>
                 <br /> 
                {window.innerWidth < 1000 ? null : <img className="resize-collection" src={Youth_Art_Contest_2021} fluid />}
                <br /> <br />
              </li>
              <li>
                Beijing 2022 Winter Olympics International Youth Painting Invitational Exhibition
                <p style={{textAlign: "left"}}>
                  The invitational exhibition received nearly 30,000 works by young people world-wise, including China’s 31 provinces, autonomous regions and municipalities and Hong Kong, Macao and Taiwan, as well as 45 foreign countries including Australia, Britain, France, Germany, Greece, Italy, Malaysia, Pakistan, Russia, the Republic of Korea (ROK), Spain, and the United States.
                  After three rounds of professional evaluation by the expert panel and the final review of the organizing committee, the event selected out 317 international winners and 600 Chinese winners of the first, second and third prizes.   
                  <ul>
                  <li>Ivanka &nbsp;&emsp; Grade 4 &nbsp;&emsp; Silver Award</li>
                  <li>Clarie &nbsp;&nbsp;&emsp; Grade 5 &nbsp;&emsp; Bronze Award</li>
                  </ul>
                </p>
                {window.innerWidth < 1000 ? null : <img className="resize-collection" src={Beijing_Olympic_2022} fluid />}
                <br /> <br />
              </li>
              <li>
                AP Art Full Score Artwork:
                <br /> <br />
                {window.innerWidth < 1000 ? null : <img className="resize-collection" src={apArt2021_1} fluid />}
                {window.innerWidth < 1000 ? null : <img className="resize-collection" src={apArt2021_2} fluid />}
                <br /> <br />
              </li>
              <li>Scholastic Art and Writing Awards:
                <ul>
                  <li>2 Gold Keys</li>
                  <li>5 Silver Keys</li>
                  <li>7 Honorable Mentions</li>
                </ul>
                <br />
                <div class={window.innerWidth < 1000 ? "" : "container"}>
                  {window.innerWidth < 1000 ? null : <img className="resize-collection" src={awards2021img1} fluid />}                  
                </div>
                <br />
              </li>
              <li>
                Asian American Pacific Heritage Month Art Wall:
                <ul>
                  <li>25 students' artwork featured</li>
                </ul>
                <br />
                <div class={window.innerWidth < 1000 ? "" : "container"}>
                  <Row>
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={asianAmerican1} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={asianAmerican2} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={asianAmerican3} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={asianAmerican4} fluid />
                  </Row>
                  <Row>
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={asianAmerican5} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={asianAmerican6} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={asianAmerican7} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={asianAmerican8} fluid />
                  </Row>
                </div>
                <br />
              </li>
            </ul>
          </p>
          <p>
            2020: <br />
            <ul>
              <li>
                Scholastic Art and Writing Awards:
                <ul>
                  <li>1 Silver Key</li>
                  <li>8 Honorable Mentions</li>
                </ul>
                <br />
                <div class={window.innerWidth < 1000 ? "" : "container"}>
                  <Row>
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2020Scholastic1} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2020Scholastic2} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2020Scholastic3} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2020Scholastic4} fluid />
                  </Row>
                  <Row>
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2020Scholastic5} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2020Scholastic6} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2020Scholastic7} fluid />
                  </Row>
                </div>
                <br />
              </li>              
              <li>
                Youth Art Contest:
                <ul>
                  <li>Joanna Zhang &nbsp;&emsp; Grade K-2 &nbsp; &emsp;&nbsp; Gold Award</li>
                  <li>Becky Yang &nbsp;&ensp; &nbsp;&emsp; Grade 3-5 &nbsp; &emsp;&nbsp; Gold Award</li>
                  <li>Eileen Wang &nbsp;&ensp;&nbsp;&emsp; Grade 3-5 &nbsp; &emsp;&nbsp; Gold Award</li>
                  <li>Nina Pan &nbsp;&emsp; &emsp; &ensp;&nbsp; Grade 6-8 &nbsp; &emsp;&nbsp; Gold Award</li>
                  <li>Elizabeth Yang &emsp; Grade 9-10 &emsp;&nbsp; Gold Award</li>
                  <li>Ethan Wei &emsp; &emsp; &ensp; Grade 9-10 &emsp;&nbsp; Bronze Award</li>
                  <li>Angela Qian &emsp;&ensp;&ensp; Grade 9-10 &emsp;&nbsp; Silver Award</li>
                  <li>Kayla Weiss &emsp; &emsp; Grade 11-12 &emsp; Gold Award</li>
                </ul>
                <br />
                <div class={window.innerWidth < 2000 ? "" : "container"}>
                  {window.innerWidth < 2000 ? null : <img className="resize-collection" src={img2020Youth1} fluid />}
                </div>
                <br />
              </li>
              <li>
                AP Art Full Score Artwork:
                <br /> <br />
                {window.innerWidth < 1000 ? null : <img className="resize-collection" src={img2020AP} fluid />}
                <br /> <br />
              </li>
              <li>1 student accepted to the California State Summer School for the Arts
                <br /> <br />
                <div class={window.innerWidth < 1000 ? "" : "container"}>
                  <Row>
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2020Summer1} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2020Summer2} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2020Summer3} fluid />
                  </Row>
                </div>
                <br />
              </li>
            </ul>
          </p>
          <p>
            2019:
            <ul>
              <li>1 student accepted to the CSSSA (California State Summer School for the Arts) with a scholarship</li>
              <br /> <br />
              <div class={window.innerWidth < 1000 ? "" : "container"}>
                <Row>
                  <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2019Summer1} fluid />
                  <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2019Summer2} fluid />
                  <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2019Summer3} fluid />
                </Row>
              </div>
              <br />
              <li>Toyota Dream Car Art Contest:
                <ul>
                  <li>2 students were semifinalists</li>
                </ul>
                <br />
                <div class={window.innerWidth < 1000 ? "" : "container"}>
                  <Row>
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2019Toyota1} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2019Toyota2} fluid />
                  </Row>

                </div>
                <br />
              </li>
              <li>Fine Arts League of Cupertino:
                <ul>
                  <li>1 student's artwork was selected for display</li>
                </ul>
                <br />
                <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2019FineArts} fluid />
                <br /> <br />
              </li>
            </ul>
          </p>
          <p>
            2017:
            <ul>
              <li>University and College Admission:
                <ul>
                  <li>1 student accepted by ArtCenter College with a scholarship</li>
                </ul>
                <br />
                <div class={window.innerWidth < 1000 ? "" : "container"}>
                  <Row>
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2017ArtCenter1} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2017ArtCenter2} fluid />
                  </Row>
                </div>
                <br />

              </li>
            </ul>
          </p>
          <p>
            2016:
            <ul>
              <li>Future Stars Bay Area Youth Art Exhibition:
                <ul>
                  <li>2 students won the Most Creative Award</li>
                </ul>
                <br />
                <div class={window.innerWidth < 1000 ? "" : "container"}>
                  <Row>
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2016FutureStars1} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2016FutureStars2} fluid />
                  </Row>
                </div>
                <br />
              </li>
            </ul>
            <ul>
              <li>California School Employees Association Art Contest
                <ul>
                  <li>3 students won the annual prize</li>
                </ul>
                <br />
                <div class={window.innerWidth < 1000 ? "" : "container"}>
                  <Row>
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2016California1} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2016California2} fluid />
                    <img className={window.innerWidth < 1000 ? "resize-img-mobile" : "resize-img"} src={img2016California3} fluid />
                  </Row>
                </div>
                <br />
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Awards;