import React from "react";
import { Container, Row, Col, Figure} from "react-bootstrap";
import FigureImage from 'react-bootstrap/FigureImage';
import Image from 'react-bootstrap/Image'
import img1 from '../ArtCourses/ColorPencil/1.jpg'
import img2 from '../ArtCourses/ColorPencil/2.jpg'
import img3 from '../ArtCourses/ColorPencil/3.jpg'
import img4 from '../ArtCourses/ColorPencil/4.jpg'
import img5 from '../ArtCourses/ColorPencil/5.jpg'
import img6 from '../ArtCourses/ColorPencil/6.jpg'
import img7 from '../ArtCourses/ColorPencil/7.jpg'
import img8 from '../ArtCourses/ColorPencil/8.jpg'
import img9 from '../ArtCourses/ColorPencil/9.jpg'
import img10 from '../ArtCourses/ColorPencil/10.jpg'
import img11 from '../ArtCourses/ColorPencil/11.jpg'
import img12 from '../ArtCourses/ColorPencil/12.jpg'
import img13 from '../ArtCourses/ColorPencil/13.jpg'
import img14 from '../ArtCourses/ColorPencil/14.jpg'
import img15 from '../ArtCourses/ColorPencil/15.jpg'
import img16 from '../ArtCourses/ColorPencil/16.jpg'


function ColorPencilImgs() {
    return (
        <React.Fragment>
            <Row className="mt-5">
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img1}
                        />
                    </Figure>
                </Col>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img2}
                        />
                    </Figure>
                </Col>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img3}
                        />
                    </Figure>
                </Col>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img4}
                        />
                    </Figure>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img5}
                        />
                    </Figure>
                </Col>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img6}
                        />
                    </Figure>
                </Col>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img7}
                        />
                    </Figure>
                </Col>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img8}
                        />
                    </Figure>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img9}
                        />
                    </Figure>
                </Col>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img10}
                        />
                    </Figure>
                </Col>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img11}
                        />
                    </Figure>
                </Col>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img12}
                        />
                    </Figure>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img13}
                        />
                    </Figure>
                </Col>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img14}
                        />
                    </Figure>
                </Col>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img15}
                        />
                    </Figure>
                </Col>
                <Col md={3}>
                    <Figure>
                        <Figure.Image
                            
                            src={img16}
                        />
                    </Figure>
                </Col>
            </Row>
        </React.Fragment>

    );

}

export default ColorPencilImgs;